<ng-container *transloco="let t; read:'edit-external-source-item'">
  <div class="card mt-2">
    <div class="card-body">
      <div class="card-title">
        <div class="container-fluid row mb-2">
          <div class="col-9 col-sm-9"><h4 id="anilist-token-header">{{source.name || t('title')}}</h4></div>
          <div class="col-3 text-end">
            <button class="btn btn-primary btn-sm me-1" (click)="toggleViewMode()">
              <ng-container *ngIf="isViewMode; else editMode">
                <i *ngIf="isViewMode" class="fa-solid fa-pen" aria-hidden="true"></i>
                <span class="visually-hidden">
                  {{t('edit')}}
                </span>
              </ng-container>
              <ng-template #editMode>
                {{t('cancel')}}
              </ng-template>
            </button>
            <button class="btn btn-danger btn-sm" (click)="delete()">
              <span class="visually-hidden">{{t('delete')}}</span>
              <i class="fa-solid fa-trash" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>


      <div #collapse="ngbCollapse" [(ngbCollapse)]="isViewMode">
        <form [formGroup]="formGroup">
          <div class="form-group mb-3">
            <label for="host">{{t('name-label')}}</label>
            <input id="name" class="form-control" formControlName="name" type="text"
                   [class.is-invalid]="formGroup.get('name')?.invalid && formGroup.get('name')?.touched" aria-describedby="name-validations">
            <div id="name-validations" class="invalid-feedback" *ngIf="hasErrors('name')">
              <div *ngIf="formGroup.get('name')?.errors?.required">
                {{t('required')}}
              </div>
              <div *ngIf="formGroup.get('name')?.errors?.notUnique">
                {{t('not-unique')}}
              </div>
            </div>
          </div>

          <div class="form-group mb-3">
            <label for="host">{{t('host-label')}}</label>
            <input id="host" class="form-control" formControlName="host" type="url"
                   [class.is-invalid]="formGroup.get('host')?.invalid && formGroup.get('host')?.touched" aria-describedby="host-validations">
            <ng-container *ngIf="formGroup.get('host')?.errors as errors">
              <div id="host-validations" class="invalid-feedback">
                <div *ngIf="errors.required">
                  {{t('required')}}
                </div>
                <div *ngIf="errors.pattern">
                  {{t('pattern')}}
                </div>
                <div *ngIf="errors.notUnique">
                  {{t('not-unique')}}
                </div>
              </div>
            </ng-container>

          </div>
          <div class="form-group mb-3">
            <label for="api-key">{{t('api-key-label')}}</label>
            <input id="api-key" class="form-control" formControlName="apiKey" type="text"
                   [class.is-invalid]="formGroup.get('apiKey')?.invalid && formGroup.get('apiKey')?.touched" aria-describedby="api-key-validations">
            <div id="api-key-validations" class="invalid-feedback" *ngIf="hasErrors('apiKey')">
              <div *ngIf="formGroup.get('apiKey')?.errors?.required">
                {{t('required')}}
              </div>
            </div>
          </div>
        </form>
        <div class="col-auto d-flex d-md-block justify-content-sm-center text-md-end mb-3">
          <button type="submit" class="flex-fill btn btn-primary" aria-describedby="anilist-token-header" (click)="saveForm()">{{t('save')}}</button>
        </div>
      </div>

    </div>
  </div>


</ng-container>
