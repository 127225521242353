<ng-container *transloco="let t; read: 'customize-sidenav-streams'">
  <form [formGroup]="listForm">
    <div class="row g-0 mb-3 justify-content-between">
      <div class="col-9" *ngIf="items.length >= 3">
        <label for="sidenav-stream-filter" class="form-label">{{t('filter')}}</label>
        <div class="input-group">
          <input id="sidenav-stream-filter" autocomplete="off" class="form-control" formControlName="filterSideNavStream" type="text" aria-describedby="reset-sidenav-stream-input">
          <button class="btn btn-outline-secondary" type="button" id="reset-sidenav-stream-input" (click)="resetSideNavFilter()">{{t('clear')}}</button>
        </div>
        <span role="alert" class="mt-1" *ngIf="listForm.get('filterSideNavStream')?.value">{{t('reorder-when-filter-present')}}</span>
      </div>
      <div class="col-3">
        <form [formGroup]="pageOperationsForm">
          <div class="form-check form-check-inline" style="margin-top: 23px; margin-left: 10px">
            <input class="form-check-input" type="checkbox" id="accessibility-mode" formControlName="accessibilityMode">
            <label class="form-check-label" for="accessibility-mode">{{t('order-numbers-label')}}</label>
          </div>
          <div class="form-check form-check-inline" style="margin-left: 10px">
            <input class="form-check-input" type="checkbox" id="bulk-mode" formControlName="bulkMode" >
            <label class="form-check-label" for="bulk-mode">{{t('bulk-mode-label')}}</label>
          </div>
        </form>
      </div>
    </div>
    <app-bulk-operations [modalMode]="true" [topOffset]="0" [actionCallback]="bulkActionCallback"></app-bulk-operations>
    <div style="max-height: 500px; overflow-y: auto">
      <app-draggable-ordered-list [items]="items | filter: filterSideNavStreams" (orderUpdated)="orderUpdated($event)"
                                  [accessibilityMode]="pageOperationsForm.get('accessibilityMode')!.value"
                                  [showRemoveButton]="false" [disabled]="listForm.get('filterSideNavStream')!.value"
                                  [bulkMode]="pageOperationsForm.get('bulkMode')!.value"
                                  [virtualizeAfter]="virtualizeAfter"
      >
        <ng-template #draggableItem let-position="idx" let-item>
          <app-sidenav-stream-list-item [item]="item" [position]="position" (hide)="updateVisibility($event, position)"></app-sidenav-stream-list-item>
        </ng-template>
      </app-draggable-ordered-list>
    </div>

    <h5 class="mt-3">{{t('smart-filters-title')}}</h5>
    <div class="mb-3" *ngIf="smartFilters.length >= 6">
      <label for="smart-filter-filter" class="form-label">{{t('filter')}}</label>
      <div class="input-group">
        <input id="smart-filter-filter" autocomplete="off" class="form-control" formControlName="filterSmartFilter" type="text" aria-describedby="reset-smart-filter-input">
        <button class="btn btn-outline-secondary" type="button" id="reset-smart-filter-input" (click)="resetSmartFilterFilter()">{{t('clear')}}</button>
      </div>
      <span role="alert" class="mt-1" *ngIf="listForm.get('filterSideNavStream')?.value">{{t('reorder-when-filter-present')}}</span>
    </div>
    <ul class="list-group filter-list">
      <li class="filter list-group-item" *ngFor="let filter of smartFilters | filter: filterSmartFilters">
        {{filter.name}}
        <button class="btn btn-icon" (click)="addFilterToStream(filter)">
          <i class="fa fa-plus" aria-hidden="true"></i>
          {{t('add')}}
        </button>
      </li>
      <li class="list-group-item" *ngIf="smartFilters.length === 0">
        {{t('no-data')}}
      </li>
    </ul>

    <h5 class="mt-3">{{t('external-sources-title')}}</h5>
    <div class="mb-3" *ngIf="externalSources.length >= 6">
      <label for="external-source-filter" class="form-label">{{t('filter')}}</label>
      <div class="input-group">
        <input id="external-source-filter" autocomplete="off" class="form-control" formControlName="filterSmartFilter" type="text" aria-describedby="reset-external-source-input">
        <button class="btn btn-outline-secondary" type="button" id="reset-external-source-input" (click)="resetExternalSourceFilter()">{{t('clear')}}</button>
      </div>
      <span role="alert" class="mt-1" *ngIf="listForm.get('filterSideNavStream')?.value">{{t('reorder-when-filter-present')}}</span>
    </div>
    <ul class="list-group filter-list">
      <li class="filter list-group-item" *ngFor="let source of externalSources | filter: filterExternalSources">
        {{source.host}}
        <button class="btn btn-icon" (click)="addExternalSourceToStream(source)">
          <i class="fa fa-plus" aria-hidden="true"></i>
          {{t('add')}}
        </button>
      </li>
      <li class="list-group-item" *ngIf="externalSources.length === 0">
        {{t('no-data-external-source')}}
      </li>
    </ul>
  </form>
</ng-container>
